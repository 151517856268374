@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 0.3s ease;
}

body {
    font-family: 'Montserrat', sans-serif;  /* Updated font to Montserrat */
    color: #333;
    background-color: #f2f4f8;;
    line-height: 1.8; 
}

/* ... existing CSS ... */

.section-title {
    font-size: 2em;       /* Increased font size */
    font-weight: 800;       /* Bolder font weight */
    text-align: center;  
    margin-bottom: 30px;    /* Increased space below the title */
    padding: 10px 0;        /* Adjusted padding for balance */
    width: 100%;         
    display: block;      
    background: linear-gradient(135deg, #009BFF, #00D0FF); /* Linear gradient background */
    color: #fff;            /* White font color for contrast */
    transform: skewY(-3deg); /* Skew effect for uniqueness */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
    border-radius: 5px;     /* Rounded edges for modern look */
    margin-top: 10px;
}

/* ... existing CSS ... */

.about {
    padding: 80px 0;
    background-color: #f2f4f8; 
}

.about-inner {
    display: flex;
    align-items: start; 
    gap: 40px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
    overflow: hidden; /* To ensure the border-radius affects child elements */
}

.about-text, .about-image {
    padding: 20px;
    position: relative;
}

/* Modern About Section */
.about-inner {
    display: flex;
    align-items: center;
    gap: 50px;
}

.section-title-modern {
    font-size: 3em;
    color: #009BFF;
    margin-bottom: 20px;
}

.intro-text {
    font-size: 1.5em;
    margin-bottom: 30px;
    text-align: center;
}

.benefits {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.benefit-card {
    flex: 1;
    min-width: 200px;
    background-color: #f9fafc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s ease;
    text-align: center;
}

.benefit-card:hover {
    transform: translateY(-5px);
}

.benefit-card .icon {
    font-size: 2em;
    margin-bottom: 10px;
    color: #009BFF;
}

.feature-card, .benefit-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}




.about-image {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2; /* to move the image to the right side */
}

.about-image img {
    width: auto;
    max-height: 500px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
}

.about-text {
    flex: 1;
}

.faq-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.container {
    max-width: 100%; /* This will ensure the content stretches the full width of the viewport */
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}


.icon {
    margin-right: 10px;
    font-size: 1.5em;
}

/* Header Styles */
/* Header Styles */
.header {
    background: linear-gradient(135deg, #009BFF, #00D0FF); 
    color: #fff;
    padding: 60px 0; 
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); 
}

.bot-action-btn {
    background-color: #5E72E4;
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;

    /* Add a hover effect */
    &:hover {
        background-color: #324cdd;
    }
}


.header h1 {
    font-size: 3em; 
    font-weight: 700; 
    margin-bottom: 15px; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); 
}

.header p {
    max-width: 800px; 
    margin: 0 auto 30px; 
    font-size: 1.1em; 
    opacity: 0.9; 
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); 
}


.btn.btn-light.btn-lg {
    background: #f2f4f8;; /* Light background */
    border: none;
    color: #007BFF; /* Blue text color */
    padding: 12px 30px;
    border-radius: 50px;
    font-size: 1.2em;
    font-weight: 1000;
    cursor: pointer;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Prominent shadow */
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.proceed-payment-btn {
    background: linear-gradient(135deg, #FF6B6B, #FF9270); /* Coral gradient background */
    border: none; 
    color: #fff; /* White font for better contrast */
    padding: 15px 30px; /* More padding for a bigger button */
    border-radius: 50px; /* Rounded edges for a pill-like button */
    font-size: 1.2em; 
    font-weight: 600; /* Semi-bold font for clear readability */
    transition: all 0.3s ease; /* Transition for hover effects */
    cursor: pointer; /* Hand cursor on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1); /* Light shadow */
}

.proceed-payment-btn:hover {
    background: linear-gradient(135deg, #FF4D4D, #FF7A50); /* Slightly darkened coral gradient for hover */
    transform: translateY(-2px); /* Small lift effect on hover */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.proceed-payment-btn:active {
    transform: translateY(0); /* Resetting lift effect on active click */
}


.btn.btn-light.btn-lg:hover {
    background: #e7e9ed; /* Slightly darker on hover */
    color: #005bb5; /* Darker blue text on hover */
    transform: translateY(-3px);
    box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}
.btn.btn-light.btn-lg:active {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Reduced shadow on active */
}

 


/* Features Styles */
.features {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 60px 0; /* Increased padding */
    background-color: #f2f4f8;; /* Lighter background */
}

.feature-card {
    flex: 1;
    min-width: 200px;
    background-color: #fff;
    border-radius: 10px; /* Rounder corners */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1); /* Light shadow */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: calc(33.33% - 20px); /* for 3 cards in a row, adjust accordingly */
}

.feature-card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
}
/* Testimonials */

/* Pricing */
.pricing {
    background: #f2f4f8;
    padding: 50px 0;
    display: flex;
    justify-content: center;
}

.card-container {
    width: 80%; /* Increased to 80% for better spacing on larger screens */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modern-card {
    border: none;
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    width: 100%; /* Make it full width by default for better responsiveness */
    max-width: 800px; /* Retain the maximum width constraint */
    padding: 20px;
}

/* ... (rest of the CSS remains unchanged) ... */


.modern-card:hover {
    transform: translateY(-10px); /* Lift up the card on hover for a dynamic effect */
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.12); /* Enhanced shadow for lift effect */
}

.price-tag, .maintenance-tag {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    padding: 10px;
    background: linear-gradient(135deg, #009BFF, #00D0FF); /* Gradient background for emphasis */
    color: #fff; /* White font for contrast */
    border-radius: 10px; /* Rounded corners for a modern look */
}

.price-amount, .maintenance-amount {
    font-size: 2em; /* Bigger font size for emphasis */
    font-weight: 700; /* Bold font for clear readability */
}

.price-info, .maintenance-info {
    font-size: 1em; /* Adjusted font size */
    opacity: 0.8; /* Slight transparency for a modern look */
}

.modern-btn {
    background: linear-gradient(135deg, #009BFF, #00D0FF); /* Gradient background for a modern button look */
    border: none; 
    color: #fff; /* White font for better contrast */
    padding: 10px 20px; /* More padding for a bigger button */
    border-radius: 50px; /* Rounded edges for a pill-like button */
    font-size: 1.1em; 
    font-weight: 600; /* Semi-bold font for clear readability */
    transition: all 0.3s ease; /* Transition for hover effects */
}

.modern-btn:hover {
    background: linear-gradient(135deg, #007BFF, #0090FF); /* Slightly changed gradient for hover */
}

/* ... existing CSS ... */

.features-list {
    list-style-type: none;
    padding-left: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.features-list li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    font-size: 1em;
    line-height: 1.5;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.features-list li::before {
    content: '\2713'; /* Unicode character for a check mark */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #009BFF; 
    font-size: 1.2em;
}

.features-list li:hover {
    transform: translateX(5px);
    color: #007BFF;
}

/* ... existing CSS ... */



/* FAQ */
/* FAQ */
/* ... Other styles ... */
/* Usage Example Styling */

.usage-example {
    padding: 80px 0;
    background-color: #f2f4f8; 
}

.usage-content {
    display: flex;
    align-items: start; 
    gap: 40px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
    overflow: hidden; /* To ensure the border-radius affects child elements */
}

.usage-text, .usage-image {
    padding: 20px;
    position: relative;
}

/* Modern Usage Example Section */
.usage-content {
    display: flex;
    align-items: center;
    gap: 50px;
}

.usage-image {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2; /* to move the image to the right side */
}

.usage-image img {
    width: auto;
    max-height: 500px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
}

.usage-text {
    flex: 1;
}








/* FAQ Styling */
/* ... Previous styles ... */

/* Updated FAQ Styling */
.faq {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-container {
    width: 100%;
    max-width: 800px; /* Set a maximum width for the FAQ items container */
}

.faq-item {
    margin-bottom: 30px;
    width: 100%; 
}

.faq-content {
    max-width: 800px;  /* Limiting the width */
    width: 100%;
}

.faq-item {
    margin-bottom: 30px;  /* Increased spacing between items for clarity */
    width: 100%;  /* Ensuring each FAQ item takes the full width of its container */
}

.faq-question {
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

.faq-question:hover {
    background-color: #f2f4f8;
    transform: translateY(-3px);
}

.faq-answer {
    margin-top: 10px;
    padding: 15px 20px;
    background-color: #f9fafc;
    border-radius: 15px;
    font-size: 0.9em;
    color: #666;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    transition: max-height 0.3s ease;
}

.icon {
    font-size: 1.5em;
    transition: transform 0.3s ease;
}

.faq-item .faq-question .icon {
    transform: rotate(0deg);
}

.faq-item.active .faq-question .icon {
    transform: rotate(180deg);
}



.icon {
    font-size: 1.5em;
}


/* Contact */
.contact {
    text-align: center;
}

a {
    color: #007BFF;
    text-decoration: none;
}

/* Login and Register Container */
.form-container {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    max-width: 400px; /* Limiting the width of the form container */
    margin: 50px auto; /* Centering the container on the page with some margin at the top */
}

/* Form Fields */
.form-container input[type="email"], 
.form-container input[type="password"] {
    width: 100%;
    padding: 12px 15px;
    margin: 10px 0;
    border: none;
    background-color: #f9fafc; /* Light grey background */
    border-radius: 5px; /* Slight rounded corners */
    font-size: 1em;
    color: #333;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.form-container input[type="email"]:focus, 
.form-container input[type="password"]:focus {
    outline: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 5px #009BFF; /* Highlight with blue color */
    transform: translateY(-2px); /* Slight lift effect */
}

/* Adjusting the proceed-payment-btn for the form */
.form-container .proceed-payment-btn {
    width: 100%;
    margin-top: 20px; /* Some margin at the top */
}


/* General Mobile Styles */
/* ... All the initial styles ... */

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    
    /* Adjusting Container Padding */
    .container {
        padding: 15px;
    }

    /* Adjusting Header Styles */
    .header h1 {
        font-size: 2em; 
    }

    .header p {
        font-size: 1em; 
        max-width: 100%; 
    }

    /* Adjusting Features Layout */
    .features {
        flex-direction: column;
    }

    .feature-card {
        margin-bottom: 20px;
    }

    /* Adjusting About Section Layout */
    .about {
        flex-direction: column;
        gap: 10px;
    }

    .about-text, .about-image {
        width: 100%; /* Taking full width on mobile */
        padding: 0;
    }

    /* Adjusting Testimonials Section Layout */
    .testimonials blockquote {
        width: 100%; 
        margin-bottom: 20px;
    }

    /* Adjusting Pricing Card for Better Spacing */
    .pricing-card {
        width: 100%; /* Taking full width on mobile */
        margin-bottom: 20px; /* Adding spacing between cards */
    }

    /* Adjusting FAQ and Contact Sections */
    .faq, .contact {
        padding: 30px 0; /* Reducing vertical padding */
    }

    /* Adjusting Usage Example Section Layout */
    .usage-example {
        flex-direction: column;
        gap: 10px;
    }

    .usage-text, .usage-image {
        width: 100%;
        padding: 0;
    }

    /* Adjusting Privacy Policy and Terms of Service for readability */
    h1, h2 {
        font-size: 1.3em;
    }

    .section-title {
        font-size: 2em;
    }

    p {
        font-size: 1em;
    }

    .header, .about, .usage-example {
        text-align: center;
    }

    .header h1, .about .section-title, .usage-example .section-title {
        font-size: 2em;
    }

    .header p, .about-text p, .usage-text p {
        font-size: 1em;
    }

    .about-inner, .usage-content, .faq-content {
        flex-direction: column;
        align-items: center; /* Center aligned items */
    }

    /* Making sure all flex children in these sections take up the full width */
    .about-text, .about-image, .usage-text, .usage-image, .faq-item {
        width: 100%;
    }

    /* Adjust padding/margin for elements for better spacing on mobile */
    .about-text, .about-image {
        padding: 10px;
    }

    .usage-text ul li {
        margin-bottom: 10px;
    }

    .about-image img, .usage-example .about-image img {
        max-width: 90%;
        margin: 20px auto;
    }

    /* Features */
    .features {
        padding: 30px 0; /* Reduce the padding */
    }

    .feature-card {
        margin-bottom: 15px; /* Adjust margin between feature cards */
        padding: 10px; /* Reduce padding inside each card */
    }

    .feature-card h2 {
        font-size: 1.5em; /* Reduce font size for feature titles */
    }

    .feature-card p {
        font-size: 0.9em; /* Reduce font size for feature descriptions */
    }

    .feature-card img {
        width: 40px; /* Reduce size of the feature images/icons */
        margin-right: 5px; /* Adjust margin for the images/icons */
    }
    /* Pricing */
    .modern-card {
        padding: 10px;
    }

    .price-tag, .maintenance-tag {
        margin: 15px 0;
    }

    .price-amount, .maintenance-amount {
        font-size: 1.8em;
    }

    /* FAQ */
    .faq-container {
        padding: 0;
    }

    /* Adjusting Container Padding */
    .container {
        padding: 15px;
    }

    .faq-question, .faq-answer {
        font-size: 1em;
    }

    /* Contact */
    .contact {
        font-size: 1em;
    }

    /* General Adjustments */
    .container {
        padding: 10px;
    }

    .whatsapp-demo {
        white-space: nowrap; /* Prevents wrapping */
        overflow: hidden; /* Ensures any overflow is hidden */
        text-overflow: ellipsis; /* Adds an ellipsis if the text overflows */
    }
}



@media only screen and (max-width: 768px) {
    /* ... existing media queries ... */

    .card-container {
        width: 90%; /* Increased width for better fit on mobile screens */
    }

    .modern-card {
        padding: 15px; /* Slightly reduced padding for mobiles */
    }

    .price-tag, .maintenance-tag {
        margin: 15px 0; /* Reduced margin for mobiles */
    }

    .price-amount, .maintenance-amount {
        font-size: 2em; /* Reduced font size for mobiles */
    }
}
