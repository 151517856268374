/* Navbar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    background-color: #009BFF;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;
}

.navbar-brand {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-weight: 700;
    color: #ffffff;
}

.navbar-items {
    display: flex;
    gap: 20px;
    font-weight: bold;
}

.navbar-item {
    text-decoration: none;
    padding: 8px 20px;
    border-radius: 30px;
    color: #ffffff;
    transition: background-color 0.3s ease;
    font-weight: 600;
}

.navbar-item.active,
.navbar-item:active {
    background: linear-gradient(135deg, #FF6B6B, #FF9270);
}

/* Burger Icon Styles */
.burger-icon {
    display: none;
    font-size: 2em;
    cursor: pointer;
    color: #ffffff;
}

.menu {
    display: none;
    position: fixed;
    top: 0;
    right: -100%; /* Initially hidden on the right side */
    width: 300px;
    height: 100vh;
    background-color: #009BFF;
    transition: right 0.3s ease-out;
    z-index: 1010;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
}

.menu.open {
    display: block; /* Make sure it's displayed */
    right: 0; /* Slide in when open */
}

.menu a {
    display: block;
    padding: 15px 20px;
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-weight: 600;
}


.close-button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 1.5em;
    cursor: pointer;
    margin: 15px;
    display: block;
    text-align: right;
    transition: color 0.2s ease;
}

.close-button:hover {
    color: #00D0FF;
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
    }

    .burger-icon {
        display: block;
    }

    .navbar-items {
        display: none;
    }
}
