/* contact.css */

/* General styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 0.3s ease;
}

body {
    font-family: 'Montserrat', sans-serif;
    color: #333;
    background-color: #f2f4f8;
    line-height: 1.8;
}

.container {
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

/* Modern Contact page styling */
.contact-section {
    background-color: #f2f4f8;
    padding-top: 0px;
}

.section-title::after {
    content: "";
    background-color: #009BFF;
    height: 4px;
    width: 80px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
}

.contact-form {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.contact-form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    box-shadow: 0 0 50px 15px rgba(0, 155, 255, 0.1);
    z-index: -1;
}

.contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #555;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="password"],
.contact-form textarea {
    width: 100%;
    padding: 14px 16px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1em;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.contact-form textarea {
    min-height: 150px;
    resize: vertical;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="password"]:focus,
.contact-form textarea:focus {
    border-color: #009BFF;
    box-shadow: 0 4px 20px rgba(0, 155, 255, 0.2);
}

.contact-form button {
    padding: 14px 20px;
    background-color: #009BFF;
    color: #fff;
    border: none;
    border-radius: 50px;
    font-size: 1.1em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.contact-form button:hover {
    background-color: #007BFF;
    transform: translateY(-2px);
}

.contact-form button:active {
    transform: translateY(0);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

/* Enhancements to make the login/register form resemble the contact page */

.contact-form form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centering the form elements */
}

.contact-form label {
    align-self: flex-start; /* Aligning the labels to the start */
}

.contact-form input[type="password"],
.contact-form input[type="email"] {
    margin-bottom: 30px; /* Increasing the margin for better spacing */
}

.contact-form button {
    width: 100%; /* Full width button */
    margin-top: 20px; /* Some space above the button */
}


/* Responsive adjustments */
@media only screen and (max-width: 768px) {
    .container {
        padding: 15px;
    }

    .contact-form {
        padding: 30px;
    }
}



/* Thank You message styling */
.thank-you-message {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transform: translateY(0);
    opacity: 1;
}

.thank-you-message h3 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #009BFF;
}

.thank-you-message p {
    font-size: 1.2em;
}

/* Animation for appearance */
@keyframes fadeInUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.thank-you-message {
    animation: fadeInUp 0.5s forwards;
}
